import React, { useRef, useState } from 'react';
import YandexMap from './map/YandexMap'; // Убедитесь, что путь корректный
import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  TextField, 
  Button, 
  Paper, 
  MenuItem 
} from '@mui/material';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const ContactsPage = () => {
  const formRef = useRef();
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      'service_qcgdf28', 
      'template_38uwb9o', 
      formRef.current, 
      'M_8smC3lPV5M7OOsp'
    ).then((result) => {
      console.log(result.text);
      setSuccessMessage(t('contacts.form.success'));
      setErrorMessage('');
      e.target.reset();
    }, (error) => {
      console.log(error.text);
      setErrorMessage(t('contacts.form.error'));
      setSuccessMessage('');
    });
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* Заголовок страницы */}
      <Typography 
        variant="h3" 
        component="h1" 
        align="center" 
        gutterBottom 
        sx={{ fontWeight: 'bold' }}
      >
        {t('contacts.pageTitle')}
      </Typography>

      {/* Контактная информация и форма обратной связи */}
      <Grid container spacing={4}>
        {/* Левая колонка - контактная информация */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              {t('contacts.info.title')}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>{t('contacts.info.addressLabel')}:</strong> {t('contacts.info.address')}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>{t('contacts.info.phoneLabel')}:</strong> {t('contacts.info.phone')}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>{t('contacts.info.emailLabel')}:</strong> {t('contacts.info.email')}
            </Typography>
            <Typography variant="body1">
              <strong>{t('contacts.info.hoursLabel')}:</strong> {t('contacts.info.hours')}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">
                {t('contacts.info.note')}
              </Typography>
            </Box>
          </Paper>
        </Grid>

        {/* Правая колонка - форма обратной связи */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              {t('contacts.form.title')}
            </Typography>
            <form ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField 
                    fullWidth 
                    label={t('contacts.form.name')} 
                    name="name" 
                    required 
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                    fullWidth 
                    label={t('contacts.form.email')} 
                    name="email" 
                    type="email" 
                    required 
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                    fullWidth 
                    label={t('contacts.form.phone')} 
                    name="phone_number" 
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    label={t('contacts.form.requestReason')}
                    name="request_reason"
                    required
                  >
                    <MenuItem value="technical">{t('contacts.form.requestReasonOptions.technical')}</MenuItem>
                    <MenuItem value="product">{t('contacts.form.requestReasonOptions.product')}</MenuItem>
                    <MenuItem value="billing">{t('contacts.form.requestReasonOptions.billing')}</MenuItem>
                    <MenuItem value="other">{t('contacts.form.requestReasonOptions.other')}</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label={t('contacts.form.message')}
                    name="message"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" type="submit">
                    {t('contacts.form.submit')}
                  </Button>
                </Grid>
                {successMessage && (
                  <Grid item xs={12}>
                    <Typography variant="body1" color="success.main">
                      {successMessage}
                    </Typography>
                  </Grid>
                )}
                {errorMessage && (
                  <Grid item xs={12}>
                    <Typography variant="body1" color="error">
                      {errorMessage}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>

      {/* Раздел с картой */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          {t('contacts.map.title')}
        </Typography>
        <YandexMap />
      </Box>
    </Container>
  );
};

export default ContactsPage;
