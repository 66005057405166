import React from 'react';
import { 
  Container,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography, 
  Paper,
  Button
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ClubCardsPage() {
  const { t } = useTranslation();

  // Получаем переведённый массив привилегий из файла перевода
  const privileges = t('clubCards.privileges', { returnObjects: true });

  // Сортируем привилегии по количеству "Недоступно" (используя значение из перевода, с дефолтным значением)
  const unavailableText = t('clubCards.unavailable', { defaultValue: "Недоступно" });
  const sortedPrivileges = [...privileges].sort((a, b) => {
    const countUnavailable = (row) =>
      [row.bronze, row.silver, row.gold].filter((val) => val === unavailableText).length;
    return countUnavailable(a) - countUnavailable(b);
  });

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {/* Заголовок страницы */}
      <Typography 
        variant="h3"
        component="h1"
        gutterBottom
        sx={{ textAlign: 'center', mb: 4, fontWeight: 'bold' }}
      >
        {t('clubCards.pageTitle')}
      </Typography>

      <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
        <Table>
          {/* Шапка таблицы */}
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: '#f0f0f0',
                  fontWeight: 'bold',
                  width: '250px'
                }}
              >
                {t('clubCards.table.privileges')}
              </TableCell>
              <TableCell align="center" sx={{ minWidth: '200px' }}>
                <img 
                  src="https://storage.yandexcloud.net/aurumavatars/bronze.png" 
                  alt="Bronze Icon" 
                  style={{ width: '100px', display: 'block', margin: '0 auto' }} 
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1, color: "#CD7F32" }}>
                  {t('clubCards.table.bronze')}
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ minWidth: '200px' }}>
                <img 
                  src="https://storage.yandexcloud.net/aurumavatars/silver.png" 
                  alt="Silver Icon" 
                  style={{ width: '100px', display: 'block', margin: '0 auto' }} 
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1, color: "#C0C0C0" }}>
                  {t('clubCards.table.silver')}
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ minWidth: '200px' }}>
                <img 
                  src="https://storage.yandexcloud.net/aurumavatars/gold.png" 
                  alt="Gold Icon" 
                  style={{ width: '100px', display: 'block', margin: '0 auto' }} 
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1, color: "#FFD700" }}>
                  {t('clubCards.table.gold')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Тело таблицы */}
          <TableBody>
            {sortedPrivileges.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell
                  sx={{
                    backgroundColor: '#f0f0f0',
                    fontWeight: 500
                  }}
                >
                  {item.name}
                </TableCell>
                <TableCell align="center">{item.bronze}</TableCell>
                <TableCell align="center">{item.silver}</TableCell>
                <TableCell align="center">{item.gold}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      {/* Кнопка "Оформить карту" */}
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/app"
        size="small"
        sx={{ mt: 4, mx: 'auto', display: 'block' }}
      >
        {t('clubCards.applyButton')}
      </Button>
    </Container>
  );
}

export default ClubCardsPage;
