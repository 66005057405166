import React from 'react';
import { 
  AppBar, Toolbar, Typography, Box, Button, IconButton, 
  Select, MenuItem, Drawer, List, ListItem, ListItemButton, ListItemText 
} from '@mui/material';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language || 'ru');
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // Навигационные ссылки
  const navLinks = [
    { title: t('header.club_cards'), path: '/club-cards' },
    { title: t('header.conditions'), path: '/conditions' },
    { title: t('header.contacts'), path: '/contacts' },
    { title: t('header.app'), path: '/app' },
  ];

  // Содержимое бокового меню для мобильных устройств: логотип + навигация
  const drawer = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {/* Логотип в мобильном меню */}
      <Box sx={{ p: 2, borderBottom: '1px solid #ccc' }}>
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Typography variant="h5" component="div" sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            AURUM
          </Typography>
        </Link>
      </Box>
      <List>
        {navLinks.map((item) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton component={Link} to={item.path}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#fafafa',
        color: '#000',
        boxShadow: 'none',
        borderBottom: '1px solid #ccc',
        fontFamily: '"ALdrich RUS", serif',
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Десктоп: логотип слева, мобиль: показываем только иконку гамбургера */}
        {!isMobile ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography
                variant="h4"
                component="div"
                sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
              >
                AURUM
              </Typography>
            </Link>
          </Box>
        ) : (
          <IconButton color="inherit" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        )}

        {/* Десктоп: навигационные ссылки в строку */}
        {!isMobile && (
          <Box sx={{ display: 'flex', gap: 2 }}>
            {navLinks.map((item) => (
              <Button key={item.title} component={Link} to={item.path} color="inherit">
                {item.title}
              </Button>
            ))}
          </Box>
        )}

        {/* Правая часть: выбор языка и соцсети */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Select
            value={language}
            onChange={handleLanguageChange}
            size="small"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': { borderColor: '#000' },
              color: '#000',
              minWidth: '60px',
            }}
          >
            <MenuItem value="ru">RU</MenuItem>
            <MenuItem value="en">EN</MenuItem>
          </Select>

          <IconButton
            color="inherit"
            component="a"
            href="https://instagram.com/your_instagram_link"
            target="_blank"
            rel="noopener"
            title="Instagram"
          >
            <InstagramIcon />
          </IconButton>

          <IconButton
            color="inherit"
            component="a"
            href="https://youtube.com/your_youtube_link"
            target="_blank"
            rel="noopener"
            title="YouTube"
          >
            <YouTubeIcon />
          </IconButton>

          <IconButton
            color="inherit"
            component="a"
            href="https://t.me/your_telegram_link"
            target="_blank"
            rel="noopener"
            title="Telegram"
          >
            <TelegramIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Box>
      </Toolbar>

      {/* Боковое меню для мобильных устройств */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Header;
