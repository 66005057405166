import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ru', // начальный язык
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false // react уже защищает от XSS
    }
  });

export default i18n;
