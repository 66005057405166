import React, { useEffect, useRef } from 'react';

const YandexMap = () => {
  const mapContainer = useRef(null);

  useEffect(() => {
    // Дождемся загрузки API Яндекс.Карт
    if (window.ymaps) {
      window.ymaps.ready(init);
    } else {
      console.error("Яндекс.Карты не загружены");
    }

    function init() {
      // Задайте координаты офиса (рекомендуется использовать Яндекс.Геокодер для точных значений)
      const officeCoordinates = [55.751584, 37.535305]; // Примерные координаты. Замените их на актуальные для "г. Москва, 1-й Красногвардейский проезд, 22с2, офис 72"

      // Создаем карту в контейнере, центрируем её по координатам офиса
      const myMap = new window.ymaps.Map(mapContainer.current, {
        center: officeCoordinates,
        zoom: 16,
        controls: ['zoomControl', 'geolocationControl']
      });

      // Создаем метку (Placemark) для офиса
      const officePlacemark = new window.ymaps.Placemark(officeCoordinates, {
        balloonContent: 'г. Москва, 1-й Красногвардейский проезд, 22с2, офис 72'
      }, {
        preset: 'islands#redIcon'
      });

      // Добавляем метку на карту
      myMap.geoObjects.add(officePlacemark);
    }
  }, []);

  return (
    <div
      ref={mapContainer}
      style={{ width: '100%', height: '400px' }}
    ></div>
  );
};

export default YandexMap;
