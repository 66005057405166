import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/HeaderPage';
import HomePage from './pages/HomePage';
import ConditionsPage from './pages/ConditionsPage';
import ContactsPage from './pages/ContactsPage';
import ClubCardsPage from './pages/ClubCardsPage';
import AppDownloadPage from './pages/AppDownloadPage';
import AdminPanel from './pages/AdminPanel';
import AdminDashboard from './pages/AdminDashboard';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/club-cards" element={<ClubCardsPage />} />
        <Route path="/conditions" element={<ConditionsPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/app" element={<AppDownloadPage />} />
        <Route path="/4pK-kif-FiT-WV4" element={<AdminPanel />} />
        <Route path="/AdminDashboard" element={<AdminDashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
