// AdminDashboard.jsx
import React, { useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

// HOC, позволяющий автоматически подстраивать сетку под ширину контейнера
const ResponsiveGridLayout = WidthProvider(Responsive);

const AdminDashboard = () => {
  // Массив модулей в состоянии. Каждый модуль:
  // i: уникальный ключ для реакта и react-grid-layout
  // x, y, w, h: координаты и размеры в сетке
  // title, content: содержимое модуля
  const [modules, setModules] = useState([
    {
      i: 'module1',
      x: 0,
      y: 0,
      w: 4,
      h: 4,
      minW: 3,
      minH: 3,
      title: 'Модуль 1',
      content: 'Здесь может быть любая информация или функционал.',
    },
    {
      i: 'module2',
      x: 4,
      y: 0,
      w: 4,
      h: 4,
      title: 'Модуль 2',
      content: 'Добавьте любой контент, который нужен администратору.',
    },
    {
      i: 'module3',
      x: 8,
      y: 0,
      w: 4,
      h: 4,
      title: 'Модуль 3',
      content: 'Например, статистика, кнопки управления, отчёты и т.д.',
    },
  ]);

  // Состояние полей ввода для создания нового модуля
  const [newTitle, setNewTitle] = useState('');
  const [newContent, setNewContent] = useState('');

  // Функция для создания нового модуля
  const handleAddModule = () => {
    // Генерируем уникальный ключ (i) — например, используем текущее время
    const newKey = `module_${Date.now()}`;
    const newModule = {
      i: newKey,
      x: 0,
      y: 0,
      w: 4,
      h: 4,
      title: newTitle || 'Новый модуль',
      content: newContent || 'Контент нового модуля',
    };

    setModules((prev) => [...prev, newModule]);
    // Сброс полей ввода
    setNewTitle('');
    setNewContent('');
  };

  // Удаление модуля
  const handleRemoveModule = (moduleKey) => {
    setModules((prev) => prev.filter((m) => m.i !== moduleKey));
  };

  // Колбэк, вызывающийся при перемещении или изменении размеров
  // layout — это обновлённый массив координат
  const handleLayoutChange = (layout) => {
    setModules((prevModules) =>
      prevModules.map((module) => {
        const updated = layout.find((l) => l.i === module.i);
        return updated
          ? {
              ...module,
              x: updated.x,
              y: updated.y,
              w: updated.w,
              h: updated.h,
            }
          : module;
      })
    );
  };

  // Генерация layouts для ResponsiveGridLayout
  const generateLayouts = () => {
    const currentLayout = modules.map((mod) => ({
      i: mod.i,
      x: mod.x,
      y: mod.y,
      w: mod.w,
      h: mod.h,
      minW: mod.minW || 1,
      minH: mod.minH || 1,
      maxW: mod.maxW,
      maxH: mod.maxH,
    }));

    return {
      lg: currentLayout,
      md: currentLayout,
      sm: currentLayout,
      xs: currentLayout,
      xxs: currentLayout,
    };
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Административная панель</h1>

      {/* Форма добавления нового модуля */}
      <div style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '15px', borderRadius: '6px' }}>
        <h3>Добавить новый модуль</h3>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', maxWidth: '350px' }}>
          <input
            type="text"
            placeholder="Заголовок"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            style={inputStyle}
          />
          <textarea
            placeholder="Содержимое (текст, описание...)"
            value={newContent}
            onChange={(e) => setNewContent(e.target.value)}
            style={{ ...inputStyle, height: '80px', resize: 'vertical' }}
          />
          <button onClick={handleAddModule} style={btnStyle}>
            Добавить модуль
          </button>
        </div>
      </div>

      {/* Сетка с draggable/resize модулями */}
      <ResponsiveGridLayout
        className="layout"
        layouts={generateLayouts()}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={30}
        autoSize
        // Указываем колбэк на изменение layout
        onLayoutChange={handleLayoutChange}
        // Если хотим тянуть за любую область блока — не указываем draggableHandle
        // Если хотим тянуть только за конкретный элемент:
        // draggableHandle=".drag-handle"
      >
        {modules.map((mod) => (
          <div key={mod.i} style={blockStyle}>
            <div
              // Если хотим тянуть за заголовок — дать ему класс "drag-handle"
              className="drag-handle"
              style={dragHandleStyle}
            >
              <strong>{mod.title}</strong>
            </div>
            <p>{mod.content}</p>

            {/* Кнопка удаления модуля */}
            <button
              onClick={() => handleRemoveModule(mod.i)}
              style={{ ...btnStyle, backgroundColor: '#f44336', marginTop: '10px' }}
            >
              Удалить
            </button>
          </div>
        ))}
      </ResponsiveGridLayout>
    </div>
  );
};

// Пример простых стилей
const blockStyle = {
  border: '1px solid #ccc',
  borderRadius: '4px',
  padding: '10px',
  boxSizing: 'border-box',
  backgroundColor: '#fff',
  height: '100%', // чтобы корректно тянулось при resize
  overflow: 'auto',
};

const dragHandleStyle = {
  cursor: 'move',
  backgroundColor: '#f5f5f5',
  padding: '5px 0',
  marginBottom: '10px',
  textAlign: 'center',
  borderRadius: '4px',
};

const inputStyle = {
  padding: '8px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  fontSize: '14px',
  width: '100%',
  boxSizing: 'border-box',
};

const btnStyle = {
  padding: '8px 12px',
  borderRadius: '4px',
  border: 'none',
  backgroundColor: '#1976d2',
  color: '#fff',
  cursor: 'pointer',
};

export default AdminDashboard;
