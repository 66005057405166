import React from 'react';
import { Box, Typography, Container, Grid, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="xl" disableGutters>
      {/* Главная секция: текст слева и фото справа */}
      <Grid 
        container 
        sx={{ 
          minHeight: '80vh', 
          alignItems: 'center', 
          px: { xs: 0, md: 4 }, 
          py: { xs: 2, md: 2 }
        }}
        spacing={2}
      >
        {/* Текстовая колонка */}
        <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
          <Typography 
            variant="h3" 
            component="h1" 
            gutterBottom
            sx={{
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
              textAlign: { xs: 'center', md: 'left' }
            }}
          >
            {t('home.slogan')}
          </Typography>
        </Grid>
        {/* Колонка с фотографией */}
        <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <img 
              src="https://storage.yandexcloud.net/aurumavatars/porsche-model.png" 
              alt="Aurum" 
              style={{ width: '100%', maxWidth: '1400px', height: 'auto', objectFit: 'cover' }} 
            />
          </Box>
        </Grid>
      </Grid>

      {/* Информационный блок о магазине */}
      <Box 
        sx={{ 
          py: 4, 
          px: { xs: 2, md: 4 },
          backgroundColor: '#fff'
        }}
      >
        <Typography 
          variant="h5" 
          component="h2" 
          align="center" 
          gutterBottom 
          sx={{ fontWeight: 'bold' }}
        >
          {t('home.welcome')}
        </Typography>
        <Typography 
          variant="body1" 
          align="center" 
          sx={{ lineHeight: 1.6 }}
        >
          {t('home.storeInfo')}
        </Typography>
      </Box>

      {/* Блок информационных карточек */}
      <Box 
        sx={{ 
          py: 4, 
          px: { xs: 0, md: 2 }, 
          backgroundColor: '#f9f9f9' 
        }}
      >
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ textAlign: 'center', py: 3 }}>
              <CardContent>
                <Typography variant="h4" component="div">
                  10+
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {t('home.info.years')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ textAlign: 'center', py: 3 }}>
              <CardContent>
                <Typography variant="h4" component="div">
                  100K+
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {t('home.info.owners')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ textAlign: 'center', py: 3 }}>
              <CardContent>
                <Typography variant="h4" component="div">
                  Premium
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {t('home.info.premium')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {/* Дополнительные карточки можно добавить по аналогии */}
        </Grid>
      </Box>
    </Container>
  );
};

export default HomePage;
