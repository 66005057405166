import React from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  Paper, 
  Button 
} from '@mui/material';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { useTranslation } from 'react-i18next';

const AppPage = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="xl" disableGutters sx={{ py: 4 }}>
      {/* Герой-секция */}
      <Box 
        sx={{ 
          backgroundImage: 'url(https://storage.yandexcloud.net/aurumavatars/rolls-royce-ghost-black-badge-wallpaper-3840x2400_9.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: { xs: '40vh', md: '60vh' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          color: '#fff'
        }}
      >
        {/* Затемнение для улучшения читаемости */}
        <Box 
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <Box sx={{ position: 'relative', textAlign: 'center', px: 2 }}>
          <Typography 
            component="h1" 
            gutterBottom 
            sx={{ 
              fontWeight: 'bold', 
              fontSize: { xs: '1.8rem', md: '2.8rem' }
            }}
          >
            {t('appPage.hero.title')}
          </Typography>
          <Typography 
            component="p" 
            sx={{ 
              fontSize: { xs: '1rem', md: '1.5rem' },
              display: { xs: 'none', sm: 'block' }
            }}
          >
            {t('appPage.hero.subtitle')}
          </Typography>
        </Box>
      </Box>

      {/* Описание приложения */}
      <Container maxWidth="md" sx={{ mt: 6, textAlign: 'center' }}>
        <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
          {t('appPage.about.title')}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.6 }}>
          {t('appPage.about.description1')}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.6 }}>
          {t('appPage.about.description2')}
        </Typography>
      </Container>

      {/* Галерея скриншотов приложения */}
      <Container maxWidth="md" sx={{ mt: 6 }}>
        <Typography variant="h5" component="h3" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 3 }}>
          {t('appPage.screenshots.title')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={{ p: 1 }}>
              <Box
                component="img"
                src="https://storage.yandexcloud.net/aurumavatars/MainRUS.png"
                alt="Скриншот 1"
                sx={{ width: '100%', height: 'auto' }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={{ p: 1 }}>
              <Box
                component="img"
                src="https://storage.yandexcloud.net/aurumavatars/DetailRUS.png"
                alt="Скриншот 2"
                sx={{ width: '100%', height: 'auto' }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={{ p: 1 }}>
              <Box
                component="img"
                src="https://storage.yandexcloud.net/aurumavatars/WaletRUS.png  "
                alt="Скриншот 3"
                sx={{ width: '100%', height: 'auto' }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      {/* Раздел скачивания для Android */}
      <Container maxWidth="md" sx={{ mt: 6, textAlign: 'center' }}>
        <Paper sx={{ p: 4 }}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>
              <Button 
                variant="contained" 
                color="primary" 
                size="large" 
                startIcon={<AndroidIcon />}
                // Замените href на реальную ссылку для скачивания Android-приложения
                href="#"
              >
                {t('appPage.download.android')}
              </Button>
            </Grid>
            <Grid item>
              <Button 
                variant="contained" 
                color="secondary" 
                size="large" 
                startIcon={<AppleIcon />}
                disabled
              >
                {t('appPage.download.ios')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Container>
  );
};

export default AppPage;
