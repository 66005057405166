import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConditionsPage = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        gutterBottom
        sx={{ fontWeight: 'bold' }}
      >
        {t('conditions.pageTitle')}
      </Typography>

      {/* Пользовательское соглашение */}
      <Box sx={{ my: 4 }}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          {t('conditions.userAgreement.title')}
        </Typography>
        <Typography
          variant="body1"
          sx={{ whiteSpace: 'pre-line', lineHeight: 1.6 }}
        >
          {t('conditions.userAgreement.text')}
        </Typography>
      </Box>

      {/* Политика конфиденциальности */}
      <Box sx={{ my: 4 }}>
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          {t('conditions.privacyPolicy.title')}
        </Typography>
        <Typography
          variant="body1"
          sx={{ whiteSpace: 'pre-line', lineHeight: 1.6 }}
        >
          {t('conditions.privacyPolicy.text')}
        </Typography>
      </Box>

      {/* Дополнительные условия */}
      <Box sx={{ my: 4 }}>
        <Typography
          variant="h5"
          component="h3"
          gutterBottom
          sx={{ fontWeight: 'bold' }}
        >
          {t('conditions.additional.title')}
        </Typography>
        <Typography
          variant="body1"
          sx={{ whiteSpace: 'pre-line', lineHeight: 1.6 }}
        >
          {t('conditions.additional.text')}
        </Typography>
      </Box>
    </Container>
  );
};

export default ConditionsPage;
