import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  TextField, 
  Button, 
  Typography, 
  Box 
} from '@mui/material';

const AdminPanel = () => {
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://aurumserver.tech:3000/admin/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: adminEmail, password: adminPassword }),
      });
      const data = await response.json();
      if (!response.ok) {
        alert(data.error || 'Ошибка авторизации');
      } else {
        alert('Авторизация успешна');
        // Пример: если сервер отдал token, сохранить:
        // localStorage.setItem('token', data.token);
        // И перейти на страницу админ-панели:
        navigate('/AdminDashboard');
      }
    } catch (error) {
      console.error('Ошибка:', error);
      alert('Ошибка при выполнении запроса');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Панель администратора
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          label="Email"
          type="email"
          fullWidth
          required
          value={adminEmail}
          onChange={(e) => setAdminEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Пароль"
          type="password"
          fullWidth
          required
          value={adminPassword}
          onChange={(e) => setAdminPassword(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Войти
        </Button>
      </Box>
    </Container>
  );
};

export default AdminPanel;
